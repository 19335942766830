<template>
  <div>
    <v-row justify="center">
      <v-col lg="8">
        <h1>Client dashboard</h1>
        <div v-if="loading">Loading...</div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="8">
        <p>Hello, {{ user }}</p>
        <div v-if="applications.length===0">There are no applications.</div>
        <div v-else>
          <BaseApplicationCard
            v-for="a in applications"
            :key="a.id"
            :application-id="a.id"
            :application-content="a"
            @open="openApplication"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getUserInfo, getUserApplications, getCurrentUserId } from '@/services/firebase';
import BaseApplicationCard from '@/components/BaseApplicationCard.vue';

export default {
  name: 'DashboardClient',
  components: { BaseApplicationCard },
  data() {
    return {
      user: '',
      applications: [],
      loading: false,
    };
  },
  methods: {
    cardSummary(data) {
      return data;
    },

    openApplication(applicationId) {
      this.$router.push({
        name: 'Application',
        params: {
          id: applicationId,
          isNew: false,
        },
      });
    },

    loadUserInfo() {
      this.user = '...';
      getUserInfo(getCurrentUserId())
        .then((response) => {
          this.user = response.data().name;
        });
    },

    async loadApplicationData() {
      this.loading = true;

      const response = await getUserApplications(getCurrentUserId());

      this.applications = response.docs.map((application) => {
        const data = {
          ...application.data(),
          ...{
            id: application?.id,
          },
        };

        return data;
      });

      this.loading = false;
    },
  },
  async created() {
    this.loadUserInfo();

    await this.loadApplicationData();
  }
  ,
};

</script>

<style scoped>

</style>
