var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-card', {
          staticClass: "mx-auto mb-5",
          attrs: {
            "elevation": hover ? 16 : 5
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('open', _vm.applicationId);
            }
          }
        }, [_c('v-list-item', {
          attrs: {
            "three-line": ""
          }
        }, [_c('v-list-item-content', [_c('div', {
          staticClass: "text-overline mb-4"
        }, [_vm._v(" #" + _vm._s(_vm.applicationId) + ", dated " + _vm._s(_vm.applicationCreatedAt) + " ")]), _c('v-list-item-subtitle', [_vm._v(" Status: " + _vm._s(_vm.applicationContent.data.common.status) + " ")])], 1), _c('v-list-item-avatar', {
          attrs: {
            "size": "60",
            "color": "primary"
          }
        }, [_c('span', {
          staticClass: "user-initials"
        }, [_vm._v(_vm._s(_vm.userInitials))])])], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }