var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('h1', [_vm._v("Client dashboard")]), _vm.loading ? _c('div', [_vm._v("Loading...")]) : _vm._e()])], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('p', [_vm._v("Hello, " + _vm._s(_vm.user))]), _vm.applications.length === 0 ? _c('div', [_vm._v("There are no applications.")]) : _c('div', _vm._l(_vm.applications, function (a) {
    return _c('BaseApplicationCard', {
      key: a.id,
      attrs: {
        "application-id": a.id,
        "application-content": a
      },
      on: {
        "open": _vm.openApplication
      }
    });
  }), 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }