<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="mx-auto mb-5"
      :elevation="hover? 16 : 5"
      @click="$emit('open', applicationId)"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">
            #{{ applicationId }},
            dated {{ applicationCreatedAt }}
          </div>
          <v-list-item-subtitle>
            Status: {{ applicationContent.data.common.status }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar
          size="60"
          color="primary"
        >
          <span class="user-initials">{{ userInitials }}</span>
        </v-list-item-avatar>
      </v-list-item>
    </v-card>
  </v-hover>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';

import dateFormat from 'dateformat';

export default {
  name: 'BaseApplicationCard',

  props: {
    applicationId: {
      type: String,
      required: true,
    },
    applicationContent: {
      type: Object,
      required: true,
    },
  },

  computed: {
    applicationCreatedAt() {
      const isCreatedSecondsExists = Boolean(this.applicationContent?.created?.seconds);
      const isCreatedNanosecondsExists = Boolean(this.applicationContent?.created?.nanoseconds);

      const isCreatedTimeExist = Boolean(isCreatedSecondsExists && isCreatedNanosecondsExists);

      if (isCreatedTimeExist) {
        const createdAt = new firebase.firestore.Timestamp(
          this.applicationContent.created.seconds,
          this.applicationContent.created.nanoseconds,
        );

        return dateFormat(createdAt.toDate(), 'dd/mm/yyyy');
      }

      return '----';
    },
    userInitials() {
      const userName = this.applicationContent?.data?.common?.client?.auth?.displayName;
      const initials = userName.substr(0, 1);

      return initials;
    },
  },
};
</script>

<style scoped>
  .user-initials {
    color: #fff;
    text-transform: uppercase;
  }
</style>
